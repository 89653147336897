import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import logoBlack from "images/fackl.png"
import logoWhite from "images/fackl_white.png"
import scrollTo from 'gatsby-plugin-smoothscroll';

import {
  Toolbar,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  Popover,
  Typography,
  IconButton,
  Button,
  Link
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MenuIcon from '@material-ui/icons/Menu';
import { Image, DarkModeToggler } from 'components/atoms';

const useStyles = makeStyles(theme => ({
  flexGrow: {
    flexGrow: 1,
  },
  navigationContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  toolbar: {
    zIndex: 999,
    maxWidth: theme.layout.contentWidth,
    width: '100%',
    margin: '0 auto',
    padding: theme.spacing(0, 2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 8),
    },
  },
  navLink: {
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
  listItem: {
    cursor: 'pointer',
    '&:hover > .menu-item, &:hover svg': {
      color: theme.palette.primary.dark,
    },
    '&.menu-item--no-dropdown': {
      paddingRight: 0,
    },
  },
  listItemActive: {
    '&> .menu-item': {
      color: theme.palette.primary.dark,
    },
  },
  listItemText: {
    flex: '0 0 auto',
    marginRight: theme.spacing(2),
    whiteSpace: 'nowrap',
  },
  listItemButton: {
    whiteSpace: 'nowrap',
  },
  listItemIcon: {
    minWidth: 'auto',
  },
  popover: {
    padding: theme.spacing(4),
    border: theme.spacing(2),
    boxShadow: '0 0.5rem 2rem 2px rgba(116, 123, 144, 0.09)',
    minWidth: 350,
    marginTop: theme.spacing(2),
  },
  iconButton: {
    marginLeft: theme.spacing(2),
    padding: 0,
    '&:hover': {
      background: 'transparent',
    },
  },
  expandOpen: {
    transform: 'rotate(180deg)',
    color: theme.palette.primary.dark,
  },
  logoContainer: {
    width: 75,
    // width: 100,
    // height: 28,
    [theme.breakpoints.up('md')]: {
      width: 95,
      // width: 120,
      // height: 32,
    },
  },
  logoImage: {
    width: '100%',
    height: '100%',
    // margin: 'auto'
    // width:'20px'
  },
  menu: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  menuItem: {
    marginRight: theme.spacing(5),
    '&:last-child': {
      marginRight: 0,
    },
  },
  menuGroupItem: {
    paddingTop: 0,
  },
  menuGroupTitle: {
    textTransform: 'uppercase',
  },
}));

const Topbar = ({ themeMode, themeToggler, onSidebarOpen, pages, className, ...rest }) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const [openedPopoverId, setOpenedPopoverId] = useState(null);

  const handleClick = (event, popoverId) => {
    setAnchorEl(event.target);
    setOpenedPopoverId(popoverId);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenedPopoverId(null);
  };

  const pricing = pages.pricing;
  const faq = pages.faq;

  const MenuGroup = props => {
    const { item } = props;
    return (
      <List disablePadding>
        <ListItem disableGutters>
          <Typography
            variant="body2"
            color="primary"
            className={classes.menuGroupTitle}
          >
            {item.groupTitle}
          </Typography>
        </ListItem>
        {item.pages.map((page, i) => (
          <ListItem disableGutters key={i} className={classes.menuGroupItem}>
            <Typography
              variant="body1"
              component={'a'}
              href={page.href}
              className={clsx(classes.navLink, 'submenu-item')}
              color="textSecondary"
              onClick={handleClose}
            >
              {page.title}
            </Typography>
          </ListItem>
        ))}
      </List>
    );
  };

  // added this
  const PricingPage = () => {
    return (
      <div className={classes.menu}>
      </div>
    );
  };

  // added this
  const FaqPage = () => {
    return (
      <div className={classes.menu}>
      </div>
    );
  };



  const renderPages = id => {
    if (id === 'pricing') {
      return <PricingPage />;
    }
    if (id === 'faq') {
      return <FaqPage />;
    }
  };


  return (
    <Toolbar disableGutters className={classes.toolbar} {...rest}>
      <div className={classes.logoContainer}>
        <a href="/" title="fackl.com">

          <Image
            className={classes.logoImage}
            src={themeMode === 'light' ? logoBlack : logoWhite}
            alt="fackl.com"
            lazy={false}
          />

        </a>
      </div>
      <div className={classes.flexGrow} />
      <Hidden smDown>
        <List disablePadding className={classes.navigationContainer}>

          <ListItem
            aria-describedby={faq.id}
            className={clsx(classes.listItem, 'menu-item--no-dropdown')}
          >
            <Typography
              variant="body1"
              className={clsx(classes.listItemText, 'menu-item')}
            >
              <Link onClick={() => scrollTo("#" + faq.id)} color="textPrimary">
                {faq.title}
              </Link>
            </Typography>
          </ListItem>


          <ListItem
            aria-describedby={pricing.id}
            className={clsx(classes.listItem, 'menu-item--no-dropdown')}
          >
            <Typography
              variant="body1"
              className={clsx(classes.listItemText, 'menu-item')}
            >
              <Link onClick={() => scrollTo("#" + pricing.id)} color="textPrimary">
                {pricing.title}
              </Link>
            </Typography>
          </ListItem>




          {/*<ListItem className={clsx(classes.listItem, 'menu-item--no-dropdown')}>*/}
          {/*  <DarkModeToggler themeMode={themeMode} onClick={() => themeToggler()} />*/}
          {/*</ListItem>*/}
          <ListItem className={clsx(classes.listItem, 'menu-item--no-dropdown')}>
            <Button
              variant="outlined"
              component="a"
              href="https://app.fackl.com"
            >
              Login
            </Button>
          </ListItem>
          <ListItem className={clsx(classes.listItem, 'menu-item--no-dropdown')}>
            <Button
              variant="contained"
              color="primary"
              component="a"
              //target="blank"
              href="https://app.fackl.com"
              className={classes.listItemButton}
            >
              Sign Up
            </Button>
          </ListItem>
        </List>
      </Hidden>
      <Hidden mdUp>
        {/*<DarkModeToggler themeMode={themeMode} onClick={() => themeToggler()} />*/}
        <IconButton
          className={classes.iconButton}
          onClick={onSidebarOpen}
          aria-label="Menu"
        >
          <MenuIcon />
        </IconButton>
      </Hidden>
    </Toolbar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
  pages: PropTypes.object.isRequired,
  themeToggler: PropTypes.func.isRequired,
  themeMode: PropTypes.string.isRequired,
};

export default Topbar;
