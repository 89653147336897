export const services = [
  {
    icon: 'fas fa-phone',
    title: 'Separate your work life',
    subtitle:
        "Stop guessing if an unknown caller is an important work call or not. Always know who's calling and avoid answering spam calls.",
  },
  {
    icon: 'fas fa-mobile',
    title: 'Protect your cell number',
    subtitle:
        "Avoid getting your cell phone blocked or on spam lists. Fackl enables you to use other numbers while protecting the sacredness of your current cell.",
  },
  {
    icon: 'fas fa-list-ol',
    title: 'The future of office phones',
    subtitle:
        "Turn your cell phone into a mobile office phone with different numbers for different segments of your life.",
  },


];

export const partners = [
  {
    name: 'Airbnb',
    logo: 'https://assets.maccarianagency.com/the-front/logos/airbnb.svg',
  },
  {
    name: 'Coinbase',
    logo: 'https://assets.maccarianagency.com/the-front/logos/coinbase.svg',
  },
  {
    name: 'Dribbble',
    logo: 'https://assets.maccarianagency.com/the-front/logos/dribbble.svg',
  },
  {
    name: 'Instagram',
    logo: 'https://assets.maccarianagency.com/the-front/logos/instagram.svg',
  },
  {
    name: 'Netflix',
    logo: 'https://assets.maccarianagency.com/the-front/logos/netflix.svg',
  },
  {
    name: 'Pinterest',
    logo: 'https://assets.maccarianagency.com/the-front/logos/pinterest.svg',
  },
];

export const props = [
  'Lifetime updates',
  'Tech support',
  'Tons of assets',
  'Integration ready',
];

export const users = [
  {
    logo: 'https://assets.maccarianagency.com/the-front/logos/slack.svg',
    name: 'Slack',
  },
  {
    logo: 'https://assets.maccarianagency.com/the-front/logos/mailchimp.svg',
    name: 'Mailchimp',
  },
  {
    logo: 'https://assets.maccarianagency.com/the-front/logos/dropbox.svg',
    name: 'Dropbox',
  },
  {
    logo: 'https://assets.maccarianagency.com/the-front/logos/google-drive.svg',
    name: 'Google Drive',
  },
  {
    logo: 'https://assets.maccarianagency.com/the-front/logos/google-ad-manager.svg',
    name: 'Google Ad Manager',
  },
  {
    logo: 'https://assets.maccarianagency.com/the-front/logos/atlassian.svg',
    name: 'Atlassian',
  },
];

export const pricings = [
  {
    title: 'The Steal',
    id: "steal",
    subtitle: 'Free for the first 30 days!',
    monthly: 9,
    variable: 5,
    priceSuffix: ' / MO',
    features: [
      "First 30 days free",
      "100 mins of calling",
      '1 number',
    ],
    disclaimer: 'Monthly subscriptions will be charged on the first day of every month. Does not currently support messaging.',
    isHighlighted: true,
    buttonText: "Try for free",
    buttonLink: "https://app.fackl.com"
  },
  {
    title: 'Lite',
    id: "lite",
    subtitle: 'The most common.',
    monthly: 25,
    variable: 5,
    priceSuffix: ' / MO',
    features: [
      "250 mins of calling",
      'Custom area codes',
      '3 numbers',
    ],
    disclaimer: 'Monthly subscriptions will be charged on the first day of every month. Does not currently support messaging.',
    isHighlighted: true,
    buttonText: "Select",
    buttonLink: "https://app.fackl.com"
  },
  {
    title: 'Plus',
    id: "plus",
    subtitle: 'Everything you need.',
    monthly: 65,
    variable: 5,
    priceSuffix: ' / MO',
    features: [
      "1,000 mins of calling",
      'Custom area codes',
      '5 numbers',
    ],
    disclaimer: 'Monthly subscriptions will be charged on the first day of every month. Does not currently support messaging.',
    isHighlighted: true,
    buttonText: "Select",
    buttonLink: "https://app.fackl.com"
  },
  {
    title: 'Enterprise',
    id: "enterprise",
    subtitle: 'Custom solutions for you.',
    // monthly: 150,
    // variable: 5,
    // priceSuffix: ' / MO',
    features: [
      "Unlimited calling",
      'Custom area codes',
      'Toll free numbers',
      'Regular numbers',
      'Custom dashboard',
      'Transcribed calls'
    ],
    disclaimer: 'Monthly subscriptions will be charged on the first day of every month. Does not currently support messaging.',
    isHighlighted: true,
    buttonText: "Contact",
    buttonLink: "mailto: support@fackl.com"
  },





];


export const faq = [
{
    id: 'faq-1',
    title: 'What can I do with my new numbers?',
    subtitle: 'What can I do with my new numbers?',
    text:
      'Both call and receive calls with multiple numbers directly on your phone. You can also customize the area codes of your numbers so your caller IDs will show a custom location when making calls.',
    link: 'Check it out',
  },
  {
    id: 'faq-2',
    title: 'What features will be added?',
    subtitle: 'Our new key fobs make it so easy!',
    text:
      "We plan on adding many cool features in the future like the ability to set calling hours, automated answering machines, and more. Let us know what new features would help you!",
    link: 'Check it out',
  },
  {
    id: 'faq-3',
    title: 'How does billing work?',
    subtitle: 'Our new key fobs make it so easy!',
    text:
      'You will be charged $25 when you first sign up and then each subsequent month you will be charged a $25 subscription fee plus $0.05 per minute of calling from the previous month.',
    link: 'Check it out',
  },
  {
    id: 'faq-4',
    title: 'Am I able to send texts?',
    subtitle: 'Our new key fobs make it so easy!',
    text:
      "Texting is not currently supported. You can think of us as your virtual office phone where you can both send and receive work calls from multiple numbers.",
    link: 'Check it out',
  },
  {
    id: 'faq-5',
    title: 'Support calling outside the US?',
    subtitle: 'Our new key fobs make it so easy!',
    text:
      'We currently only support users in the United States making calls to and from other numbers in the United States. We plan on adding additional functionality in the future.',
    link: 'Check it out',
  },
  {
    id: 'faq-6',
    title: 'I would like to cancel my subscription.',
    subtitle: 'Our new key fobs make it so easy!',
    text:
      'We make it very easy to cancel your subscription at any point. Log into your dashboard, in the top right click "manage subscription", and follow the steps!',
    link: 'Check it out',
  },
];

export const reviews = [
  {
    authorPhoto: {
      src: 'https://assets.maccarianagency.com/the-front/photos/people/veronica-adams.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/veronica-adams@2x.jpg 2x',
    },
    authorName: 'Veronica Adams',
    authorOccupation: 'Growth Marketer, Crealytics',
    feedback:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  {
    authorPhoto: {
      src: 'https://assets.maccarianagency.com/the-front/photos/people/akachi-luccini.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/akachi-luccini@2x.jpg 2x',
    },
    authorName: 'Akachi Luccini',
    authorOccupation: 'Lead Generation, Alternative Capital',
    feedback:
      'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
  {
    authorPhoto: {
      src: 'https://assets.maccarianagency.com/the-front/photos/people/jack-smith.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/jack-smith@2x.jpg 2x',
    },
    authorName: 'Jack Smith',
    authorOccupation: 'Head of Operations, Parkfield Commerce',
    feedback:
      'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
  },

];
